<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.accrue_point_program") }}
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <select-multiple
            v-model="item.pos_ids"
            :options="posOptions"
            :label="$t('labels.pos')"
            custom-class="c-input-small"
          ></select-multiple>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="item.name"
            :label="$t('labels.program_name')"
            class="c-input-small"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <date-picker-model
            v-model="item.start_time"
            :label="$t('labels.start')"
            :min-date="currentDate"
          ></date-picker-model>
        </v-col>
        <v-col cols="6">
          <date-picker-model
            v-model="item.end_time"
            :label="$t('labels.end')"
            :min-date="currentDate"
          ></date-picker-model>
        </v-col>
        <v-col cols="6">
          <input-number
            v-model="item.spend"
            :label="$t('labels.spend')"
            outlined
            dense
            hide-details
            type="number"
            class="c-input-small"
          />
        </v-col>
        <v-col cols="6">
          <input-number
            v-model="item.point"
            :label="$t('labels.accrue_point')"
            outlined
            dense
            hide-details
            type="number"
            class="c-input-small"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="close">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn
        color="green darken-1"
        text
        @click="confirm"
        :disabled="isDisabledBtn"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment/moment";
import { httpClient } from "@/libs/http";

export default {
  name: "AccruePointProgramCreate",
  components: {
    // DatePicker: () => import('@/components/common/DatePicker'),
  },
  data: () => ({
    posOptions: [],
    item: {
      pos_ids: [],
      name: null,
      spend: "",
      point: "",
      start_time: null,
      end_time: null,
    },
    isLoading: false,
  }),
  computed: {
    currentDate() {
      return moment().format("YYYY-MM-DD");
    },
    isDisabledBtn() {
      if (!this.item.pos_ids || this.item.pos_ids.length === 0) {
        return true;
      }
      if (!this.item.name || !this.item.spend || !this.item.point) {
        return true;
      }
      if (!this.item.start_time || !this.item.end_time) {
        return true;
      }
      const start = moment(this.item.start_time, "DD/MM/YYYY HH:mm")
        .startOf("minute")
        .unix();
      const end = moment(this.item.end_time, "DD/MM/YYYY HH:mm")
        .endOf("minute")
        .unix();
      if (start > end) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    const { poss } = window.me;
    this.posOptions = [...poss].map((w) => ({
      value: w.id,
      text: w.code || "",
    }));
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async confirm() {
      if (this.isDisabledBtn) {
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/pos-accrue-point-save", this.item);
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.create_success"));
        this.$emit("refreshData");
        this.close();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
